import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { msalInstance } from "auth/authConfig.tsx";
import { store } from "stores/application.store.tsx";
import { impersonationActions } from "features/reducers/settings/impersonation.ts";

const acquireAccessToken = async () => {
	// This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
	const activeAccount = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();

	// Redirect to log in page when invalid account data is used
	if (!activeAccount && accounts.length === 0) {
		await msalInstance.loginRedirect();
		return;
	}

	const request = {
		scopes: [import.meta.env.VITE_APP_USE_B2C_ACCOUNT_ACCESS_TOKEN_REQUEST_SCOPES],
		account: activeAccount || accounts[0]
	};

	try {
		const authResult = await msalInstance.acquireTokenSilent(request);
		return authResult.accessToken;
	} catch (err) {
		const urlParams = new URLSearchParams(window.location.search);
		const reloaded = urlParams.get("reloaded");

		console.error(err);

		if (err instanceof Error && err.stack?.includes("BrowserAuthError")) {
			window.location.href = "/unauthorized";
			return;
		}

		await msalInstance.loginRedirect().catch((logoutErr) => {
			console.error(logoutErr);

			// If a user gets here and we've already reloaded, we can safely let the ErrorBoundary handle it
			if (reloaded === "true") {
				console.error("Auth Error: Retry failed.");
				window.location.href = "/unauthorized";
			} else {
				window.location.href = "/?reloaded=true";
			}
		});
	}

	return "";
};

const baseQuery = (baseUrl: string, skipEmulation: boolean) =>
	fetchBaseQuery({
		baseUrl: baseUrl,
		prepareHeaders: async (headers) => {
			const accessToken = await acquireAccessToken();
			headers.set("Authorization", `Bearer ${accessToken}`);

			const userEmulation = store.getState().impersonation.impersonationUser;
			if (userEmulation && !skipEmulation && userEmulation?.email) {
				headers.set("x-tandem-emulation", userEmulation?.email);
			} else if (userEmulation && !skipEmulation && userEmulation?.accountId) {
				headers.set("x-tandem-account-emulation", userEmulation?.accountId);
			}

			return headers;
		}
	});

export type APIErrorResponse = {
	errors?: Array<{
		code: number;
		message: string;
	}>;
	status?: string;
};

export const isApiErrorResponse = (response: any): response is APIErrorResponse => {
	return "errors" in response && Array.isArray(response.errors);
};

export type UntransformedResponse<T> = { data: T; status: number };

const navigateToErrorPage = (url: string) => {
	// Clear emulation state on error to prevent a user getting stuck
	store.dispatch(impersonationActions.setImpersonationUser(undefined));
	window.location.href = url;
};

const handleRedirection = (primaryStatus: number, secondaryStatus?: number, extraOptions?: any) => {
	if (primaryStatus === 401) window.location.href = "/unauthorized";
	else if (primaryStatus === 403 && secondaryStatus === 4031) navigateToErrorPage("/unapproved/pending");
	else if (primaryStatus === 403 && secondaryStatus === 4032) navigateToErrorPage("/unapproved/denied");
	else if (isNaN(primaryStatus) || primaryStatus === 500) navigateToErrorPage("/unavailable");
	else if (isNaN(primaryStatus) || primaryStatus >= 503) navigateToErrorPage("/unavailable");
	else if ((isNaN(primaryStatus) || primaryStatus === 502) && !extraOptions.handle502Error)
		navigateToErrorPage("/unavailable");
};

const importFileRoutes = ["/new-order", "/new-order/build-order", "/new-order/build-order/", "/add-ons/"];

export const baseQueryWithRedirect =
	(baseUrl: string, isRedirecting: boolean = true) =>
	async (args: any, api: any, extraOptions: any) => {
		const url = typeof args === "string" ? args : typeof args === "object" && args?.url;
		const skipEmulation = typeof url === "string" && url.includes("emulation");
		const result = await baseQuery(baseUrl, skipEmulation)(args, api, extraOptions);
		const pathname = window.location.pathname;
		const newOrderNetworkError = importFileRoutes.some((path) => pathname === path);

		if (result.error && "status" in result.error) {
			const errorResp = result?.error?.data as APIErrorResponse;
			const primaryStatus = Number(result.error.status);
			const secondaryStatus = errorResp?.errors && errorResp.errors?.length > 0 ? errorResp.errors[0]?.code : 0;

			if (isNaN(primaryStatus) && newOrderNetworkError) {
				return result;
			}

			if (isRedirecting) handleRedirection(primaryStatus, secondaryStatus, extraOptions);
		}
		return result;
	};

export const encodePostRequest = (request: Object | string | undefined | null) => {
	return {
		data: btoa(JSON.stringify(request))
	};
};
