import { Button, Grid } from "@mui/material";
import { OrderDetailViewModel, ReplacementPostModel } from "data/api/v1";
import { useMemo, useState } from "react";
import { BackText, ReplacementReviewSubmitOrder } from "constants/text";
import { v4 as uuidv4 } from "uuid";
import { useCreateReplacementOrderMutation } from "features/api/orderApi.ts";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useGetUserDetailsQuery } from "features/api/userApi.ts";
import styles from "pages/replacements-page-styles.module.css";
import { useLazyGetImageUploadDataByOrderIdQuery } from "features/api/imageApi.ts";
import { BlobServiceClient } from "@azure/storage-blob";
import { ReplacementCartItem, cartActions } from "features/reducers/replacementOrder/cart.ts";
import { submittedReplacementOrdersActions } from "features/reducers/replacementOrder/submittedReplacementOrders.ts";
import { trackGA4Event } from "utils/googleAnalytics";
import { APIErrorResponse } from "features/api/utils/apiUtils.ts";
import { models } from "types/api/viewModels.ts";

export interface ReplacementsReviewButtons {
	orderDetail: OrderDetailViewModel | null;
	accounts: models["CustomerAccountViewModel"] | undefined;
	handleReplacementError: (error: APIErrorResponse["errors"]) => void;
}

const ReplacementReviewButtons = ({ orderDetail, accounts, handleReplacementError }: ReplacementsReviewButtons) => {
	const [createReplacementOrder] = useCreateReplacementOrderMutation();
	const { data: userDetails } = useGetUserDetailsQuery();
	const cartItems: ReplacementCartItem[] = useSelector((state: RootState) => state.cart.replacements);
	const details = useSelector((state: RootState) => state.submittingReplacementOrder.order);
	const navigateTo = useNavigate();
	const [trigger] = useLazyGetImageUploadDataByOrderIdQuery();
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(false);

	const replaceableParts = useMemo(() => cartItems.map((item: any) => item.replaceableParts), [cartItems]).flat();

	const wholeCabinetOrders = cartItems.reduce((accum: any, item: any) => {
		if (item.wholeCabinetQuantity > 0) {
			accum.push({
				originalLineItemId: item.lineItemId,
				id: item.id,
				hasAttachments: item.cabinetFileUploads && item.cabinetFileUploads.length > 0,
				defectCode: item.cabinetDefectCode,
				quantityOrdered: item.wholeCabinetQuantity
			});
		}
		return accum;
	}, []);

	const cabinetPartOrders = replaceableParts.reduce((accum: any, part: any) => {
		if (part.partQuantity > 0) {
			accum.push({
				originalLineItemId: part.lineItemId,
				id: part.id,
				hasAttachments: part.partFileUploads && part.partFileUploads.length > 0,
				defectCode: part.partDefectCode,
				quantityOrdered: part.partQuantity
			});
		}
		return accum;
	}, []);

	const combinedOrders =
		cabinetPartOrders?.length > 0 ? wholeCabinetOrders.concat(cabinetPartOrders) : wholeCabinetOrders;

	const billToId = useMemo(
		() =>
			accounts?.billTos
				?.filter((item: models["BillToViewModel"]) => item.isSelected)
				.map((item: models["BillToViewModel"]) => item.billToId),
		[accounts]
	);

	const shipToAddresses = useMemo(
		() => accounts?.billTos?.map((address: models["BillToViewModel"]) => address.shipTos).flat(),
		[accounts]
	);

	const selectedAddress = shipToAddresses?.reduce((accum: any, address: any) => {
		if (address.isSelected) {
			accum.push({
				shipToId: address.shipToId
			});
		}
		return accum;
	}, []);

	const uploadToBlobStorage = async (uuID: string, newLineItems: any[]): Promise<string[]> => {
		const imageUploadData = await trigger(uuID).unwrap();
		// This should be one of three values: tandemdatastoragedev, tandemdatastoragetest, or tandemdatastorageprod
		const account = `tandemdatastorage${import.meta.env.VITE_APP_ENV}`;
		const sas = imageUploadData.credentials;
		const uploadedBlobs: string[] = [];

		// Loop through each cart item to get the uploads and lineItemNumber, to be able to upload each image from the cart
		for (const cartItem of cartItems) {
			const { cabinetFileUploads, replaceableParts, cabinetDefectCode } = cartItem;
			const partUploads = replaceableParts.map((part) => part.partFileUploads).flat();
			const combinedArray = [...cabinetFileUploads, ...partUploads];
			if (partUploads.length > 0) {
				Array.from(replaceableParts.filter((p) => p.partQuantity > 0)).forEach((part) => {
					trackGA4Event({
						event: "Replacement - Submitted Images",
						eventCategory: "replacements",
						eventLabel: "replacement images uploaded",
						eventAction: "submit",
						replacementUploadType: "part",
						numberOfFileUploads: part.partFileUploads.length,
						defectCode: part.partDefectCode
					});
				});
			}

			if (wholeCabinetOrders.length > 0 && cabinetDefectCode && cabinetFileUploads.length > 0) {
				trackGA4Event({
					event: "Replacement - Submitted Images",
					eventCategory: "replacements",
					eventLabel: "replacement images uploaded",
					eventAction: "submit",
					replacementUploadType: "whole cabinet",
					numberOfFileUploads: cabinetFileUploads.length,
					defectCode: cabinetDefectCode
				});
			}
			for await (const file of combinedArray) {
				try {
					const { fileUrl, size, name, id, originalLineItemId } = file;
					const newLineItemNumber =
						newLineItems.findIndex(
							(lineItem) => lineItem.id === id && lineItem.originalLineItemId === originalLineItemId
						) + 1;
					const fileName = `${newLineItemNumber}/${name}`;
					const blobContent = await fetch(fileUrl).then((response) => response.blob());
					const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${sas}`);
					const containerClient = blobServiceClient.getContainerClient(uuID);
					const blockBlobClient = containerClient.getBlockBlobClient(fileName);
					let contentType = "";
					if (name.endsWith(".pdf")) {
						contentType = "application/pdf";
					} else if (name.endsWith(".jpg") || name.endsWith(".jpeg")) {
						contentType = "image/jpeg";
					} else if (name.endsWith(".png")) {
						contentType = "image/png";
					}
					await blockBlobClient.upload(blobContent, size, {
						blobHTTPHeaders: {
							blobContentType: contentType
						}
					});
					uploadedBlobs.push(id);
				} catch (e) {
					dispatch(cartActions.setErrorState());
					console.error(e);
				}
			}
		}

		return uploadedBlobs;
	};

	const submitReplacement = async () => {
		const uuID: string = uuidv4();
		setDisabled((prevState) => !prevState);

		// When shipping details exists and shipping details id exists assign id
		// Otherwise the calculateShipToId should remain undefined
		// Get shipToSelection from shippingDetails when applicable otherwise select it from billingDetails using default shipToId

		const calculateShipToId = !details?.shippingDetails
			? selectedAddress[0]?.shipToId
			: details?.shippingDetails?.shipToId;
		const shipToSelection = details?.shippingDetails
			? details.shippingDetails
			: details?.billingDetails?.shipTos?.find((x) => x.shipToId === calculateShipToId);
		const hasPhoneNumber = Boolean(
			details?.shippingDetails?.address?.phoneNumber &&
				details?.shippingDetails?.address.phoneNumber.trim() !== ""
		);
		const hasEmail = Boolean(
			details?.shippingDetails?.address?.email && details?.shippingDetails?.address.email.trim() !== ""
		);
		const calculateShipToAddress = {
			phoneNumber: hasPhoneNumber ? shipToSelection?.address?.phoneNumber : undefined,
			email: hasEmail ? shipToSelection?.address?.email : undefined,
			...(!details?.isCustomerPickup && {
				name: shipToSelection?.address?.name,
				line1: shipToSelection?.address?.line1,
				line2: shipToSelection?.address?.line2,
				line3: shipToSelection?.address?.line3,
				city: shipToSelection?.address?.city,
				state: shipToSelection?.address?.state,
				zip: shipToSelection?.address?.zip,
				county: shipToSelection?.address?.county ?? ""
			})
		};

		// Populate base fields used for replacement order
		let replacementOrder: ReplacementPostModel = {
			tandemOrderId: uuID,
			originalOrderId: orderDetail?.orderId,
			lineItems: combinedOrders,
			accountId: accounts?.accountId,
			jobName: details?.jobName ?? orderDetail?.jobName,
			poNumber: details?.poNumber ?? orderDetail?.poNumber,
			billToId: String(billToId),
			isCustomerPickup: details?.isCustomerPickup ?? orderDetail?.shippingDetails?.shipVia === "CUSTOMER_PICKUP",
			orderedBy: `${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`,
			labelComments: details?.labelComments,
			shipToId: !details?.isCustomerPickup ? calculateShipToId : undefined,
			shipToAddress: calculateShipToAddress
		};

		if (replacementOrder.tandemOrderId && replacementOrder.lineItems) {
			const successfulUploads = await uploadToBlobStorage(
				replacementOrder.tandemOrderId,
				replacementOrder.lineItems
			);
			replacementOrder.lineItems.forEach((lineItem) => {
				lineItem.hasAttachments = lineItem.id ? successfulUploads.includes(lineItem.id) : false;
			});
		}
		try {
			// Encode the replacementOrder object fields to Base64
			await createReplacementOrder(replacementOrder).unwrap();
			dispatch(submittedReplacementOrdersActions.updateOrders(replacementOrder?.originalOrderId));
			navigateTo(`/replacements/confirmation/${orderDetail?.orderId}`);
		} catch (error: any) {
			if (error instanceof DOMException && error.name === "InvalidCharacterError") {
				console.error("Invalid character in string for btoa encoding:", error);
			} else if (error?.data) {
				// Handle API call errors based on status code
				if (error.data.status === 403) {
					return navigateTo("/unapproved/account");
				}

				if (error.data.status === 404) {
					return navigateTo("/ordernotfound");
				}

				if (error.data.status === 409) {
					return navigateTo(`/replacements/resubmit/${orderDetail?.orderId}`);
				}

				if (error.data.status === 400) {
					setTimeout(() => window.scrollTo(0, 0), 0);
					handleReplacementError(error.data.errors);
				}
			} else {
				console.error("An unexpected error occurred:", error);
			}
		}
	};

	const handleBackNavigation = () => {
		navigateTo(`/replacements/reason/${orderDetail?.orderId}`);
	};

	return (
		<Grid
			container
			justifyContent="flex-end"
			className={styles.replacementReviewButtons}
		>
			<Grid
				item
				order={{ xs: 2, sm: 1 }}
				xs={12}
				sm={2}
				md={1}
				className={styles.backButton}
			>
				<Button
					variant="text"
					fullWidth
					sx={{ textTransform: "uppercase", fontFamily: "Gibson Medium, sans-serif" }}
					data-testid="replacementReason-backButton"
					onClick={handleBackNavigation}
				>
					{BackText}
				</Button>
			</Grid>
			<Grid
				item
				order={{ xs: 1, sm: 2 }}
				xs={12}
				sm={4}
				md={3}
			>
				<Button
					fullWidth
					onClick={submitReplacement}
					disabled={disabled}
					variant="contained"
					data-testid="replacementReview-submitOrder"
					data-id="replacementReview-submitOrder"
				>
					{ReplacementReviewSubmitOrder}
				</Button>
			</Grid>
		</Grid>
	);
};

export default ReplacementReviewButtons;
