import {
	BuildNewOrderLineItemsContainer,
	BuildNewOrderLineItemsFlexbox,
	NewOrderFlowButtons
} from "components/NewOrders/NewOrders.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import { Alert, AlertTitle, Button, Skeleton } from "@mui/material";
import {
	NewOrderBuildOrderLabel,
	NewOrderCancelButton,
	NewOrderConfirmRemoveGroup,
	NewOrderDialogSubtitle,
	NewOrderDialogTitle,
	NewOrderEmptyBullet1,
	NewOrderEmptyBullet2,
	NewOrderPriceEstimate,
	NewOrderRemoveGroupSubtitle,
	NewOrderRemoveGroupTitle,
	NewOrderRemoveItem,
	NewOrderSubtitle,
	SlowLoadBannerTitleText,
	SlowLoadMessageText
} from "components/NewOrders/constants";
import { useLocation, useNavigate, useParams } from "react-router";
import { lineItemIdSymbol, useValidateOrderRequestMutation } from "features/api/newOrderApi.ts";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import {
	LabelSkeletonContainer,
	LineItemLoadingSkeleton,
	NewOrderSkeletonContainer
} from "components/NewOrders/BuildNewOrderLineItems/buildNewOrderLineItems.styles";
import { EmptyFlyoutHeaderText } from "constants/text";
import {
	type ConfigurationGroup,
	getConfigurationGroupsByProductLine,
	shuffleLineItems,
	useCheckForUpdatedItemsBillToAccountErrors,
	handlePriceValidationErrorMessaging
} from "components/NewOrders/utils/NewOrderUtils.tsx";
import NewOrdersSubheader from "../NewOrdersSubheader/NewOrdersSubheader";
import LeaveFlowDialog from "../../Common/LeaveFlowDialog/LeaveFlowDialog";
import DefaultMessage from "../../Common/DefaultMessage/DefaultMessage";
import { updateOrderDetails } from "pages/OrderPages/utils/OrderDetailUtil";
import { ValidateProductsRequestBody } from "types/api/products/postValidate";
import { models } from "types/api/viewModels.ts";
import FileContent from "./FileContent.tsx";
import { APIErrorResponse } from "features/api/utils/apiUtils.ts";
import { useLazyGetDraftOrderQuery } from "features/api/orderApi.ts";
import { useLazyGetAccountsQuery } from "features/api/accountApi.ts";
import ErrorBanner from "../../Common/ErrorBanner/ErrorBanner.tsx";
import useTimer from "hooks/useTimer.ts";

interface Props {
	draftOrder?: models["DraftOrderViewModel"];
	isAddOn?: boolean;
	handleSetIsLoading?: (value: boolean) => void;
}

export type LineItemErrors = { [key: string]: string | null | undefined };

const BuildNewOrderLineItems = ({ handleSetIsLoading, draftOrder, isAddOn }: Props) => {
	const { draftId } = useParams();
	const navigateTo = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const [
		fetchDraftOrder,
		{ data: loadedDraftOrder, error: draftOrderError, isFetching: isFetchingDraftOrder, isUninitialized }
	] = useLazyGetDraftOrderQuery();
	const [getAccountByProductLine] = useLazyGetAccountsQuery();
	const [validateOrderRequest, { isLoading: isValidateLoading }] = useValidateOrderRequestMutation();
	const [lineItemErrors, setLineItemErrors] = useState<LineItemErrors>({});
	const [showEstimateButton, setShowEstimateButton] = useState(true);
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const tempParsedCSV = useSelector((state: RootState) => state.newOrder.parsedTempCSV);
	const orderType = useSelector((state: RootState) => state.newOrder.newOrderDetails?.orderType);
	const newGlobalAttribute: models["PendingOrderConfigurationViewModel"] | undefined = useSelector(
		(state: RootState) => state.newOrder.newGlobalAttributes
	);
	const csvConfigurations = useMemo(
		() =>
			parsedCSV
				?.map((file) => file?.configurations?.map((config) => config))
				.filter((file) => typeof file !== "undefined")
				.flatMap((file) => file),
		[parsedCSV]
	);
	const [updatedItems, setUpdatedItems] = useState<models["PendingOrderConfigurationViewModel"][] | undefined>(
		(draftOrder?.configurations ?? csvConfigurations) as models["PendingOrderConfigurationViewModel"][]
	);
	const selectedRemovedItem = useRef<models["PendingLineItemViewModel"] | undefined>();
	const selectedRemovedConfiguration = useRef<models["PendingOrderConfigurationViewModel"] | undefined>();

	const [groupedDisplayConfigs, setGroupedDisplayConfigs] = useState<ConfigurationGroup[] | undefined>(
		getConfigurationGroupsByProductLine(updatedItems)
	);
	const [errorBannerType, setErrorBannerType] = useState<string[] | undefined>(undefined);
	const [postError, setPostError] = useState<APIErrorResponse | undefined>();

	const [accountInfoLoaded, setAccountInfoLoaded] = useState(!params?.draftId);
	const isDraftLoaded = useRef(false);
	const {
		isDone: isSlowLoadTimerDone,
		isRunning: isSlowLoadTimerRunning,
		reset: resetSlowLoadTimer,
		start: startSlowLoadTimer
	} = useTimer({ timeout: 5000 });
	const isLoading = useMemo(
		() =>
			!accountInfoLoaded ||
			isValidateLoading ||
			isFetchingDraftOrder ||
			Boolean(draftId && isUninitialized && !draftOrder?.configurations) ||
			Boolean(!isFetchingDraftOrder && !updatedItems && !draftOrderError),
		[
			accountInfoLoaded,
			draftId,
			draftOrder,
			draftOrderError,
			isFetchingDraftOrder,
			isUninitialized,
			isValidateLoading,
			updatedItems
		]
	);

	const showWaitMessage = isSlowLoadTimerDone && isValidateLoading;

	useEffect(() => {
		dispatch(newOrderActions.addValidatedOrder(undefined));
	}, [dispatch]);

	useEffect(() => {
		setGroupedDisplayConfigs((prev) => getConfigurationGroupsByProductLine(updatedItems, prev));
	}, [updatedItems]);

	useEffect(() => {
		handleSetIsLoading?.(isLoading);
	}, [handleSetIsLoading, isLoading]);

	useEffect(() => {
		if (!isValidateLoading && isSlowLoadTimerRunning) {
			resetSlowLoadTimer();
		}
	}, [isSlowLoadTimerRunning, isValidateLoading, resetSlowLoadTimer, startSlowLoadTimer]);

	useEffect(() => {
		if (draftId && !isDraftLoaded.current && !draftOrder?.configurations) {
			fetchDraftOrder(draftId);
		}
	}, [draftId, draftOrder, fetchDraftOrder]);

	// below useEffect handles draft open
	useEffect(() => {
		const draft = draftOrder ?? loadedDraftOrder;
		if (!isDraftLoaded.current && draft?.configurations && !isFetchingDraftOrder) {
			const productLines: (string | null | undefined)[] = draft.configurations
				.map((config) => config.globals?.productLine?.code)
				.filter((code) => typeof code !== "undefined" && code !== null);
			productLines.forEach((productLineCode, index) => {
				if (productLineCode) {
					getAccountByProductLine(productLineCode)
						.unwrap()
						.then((accountInfo) => {
							dispatch(newOrderActions.addProductLineAccountInfo([productLineCode, accountInfo]));
							if (index === productLines.length - 1) {
								setAccountInfoLoaded(true);
							}
						})
						.catch(() => setAccountInfoLoaded(true));
				}
			});

			if (productLines.length === 0) {
				setAccountInfoLoaded(true);
			}

			setUpdatedItems(draft.configurations);
			dispatch(newOrderActions.overwriteDraftOrder(draft));
			const shipToAddress: models["ShipToViewModel"] = {
				isActive: true,
				isSelected: true,
				shipToId: draft.shipToId,
				address: draft.shipToAddress
			};
			if (
				location.pathname === `/new-order/build-order/${params.draftId}` ||
				location.pathname === `/add-ons/build-order/${params.draftId}`
			) {
				dispatch(newOrderActions.updateOrderType(draft.orderType ?? orderType));
			}
			dispatch(newOrderActions.updateShippingDetails(shipToAddress));
			isDraftLoaded.current = true;
		}
	}, [
		dispatch,
		draftOrder,
		getAccountByProductLine,
		isFetchingDraftOrder,
		loadedDraftOrder,
		orderType,
		location.pathname,
		params.draftId
	]);

	const handleShowEstimateButton = (shouldShow: boolean) => {
		setShowEstimateButton(shouldShow);
	};

	const handleUpdateAccount = (
		account: models["CustomerAccountViewModel"] | null,
		configurationId: string | null | undefined
	) => {
		setUpdatedItems((prev) =>
			prev?.map((item) => {
				if (item.configurationId === configurationId) {
					return {
						...item,
						accountId: account?.accountId,
						accountNumber: account?.accountNumber,
						accountName: account?.accountName,
						billToId: null
					};
				}
				return item;
			})
		);
	};
	const handleUpdateBillTo = (
		billTo: models["BillToViewModel"] | null | undefined,
		configurationId: string | null | undefined
	) => {
		if (billTo === null) {
			setTimeout(() => window.scrollTo(0, 0), 0);
			setErrorBannerType(["NewOrderBillToErrorBanner"]);
		}
		setUpdatedItems((prev) =>
			prev?.map((item) => {
				if (item.configurationId === configurationId) {
					return { ...item, billToId: billTo ? billTo?.billToId : billTo };
				}
				return item;
			})
		);
	};

	const handleConfigurationUpdate = (configuration: models["PendingOrderConfigurationViewModel"]) => {
		setUpdatedItems((prev) =>
			prev?.map((item, idx) => {
				if (item.configurationId === configuration?.configurationId) {
					return { ...item, ...configuration };
				}
				return item;
			})
		);
	};

	useEffect(() => {
		if (newGlobalAttribute) {
			setUpdatedItems((prev) => {
				if (!prev?.find((config) => JSON.stringify(config) === JSON.stringify(newGlobalAttribute))) {
					return shuffleLineItems([...(prev ?? []), newGlobalAttribute], selectedRemovedItem);
				}
				return prev;
			});
			dispatch(newOrderActions.addSingleConfigToOrder(undefined));
		}
	}, [dispatch, newGlobalAttribute]);

	const handleGeneratePriceEstimate = async () => {
		startSlowLoadTimer();
		setErrorBannerType(undefined);
		setPostError(undefined);
		dispatch(newOrderActions.clearDraftError());
		setTimeout(() => window.scrollTo(0, 0), 0);
		const apiInput: ValidateProductsRequestBody = updatedItems ?? [];

		try {
			await validateOrderRequest(apiInput)
				.unwrap()
				.then(async (response) => {
					dispatch(newOrderActions.addValidatedOrder(response));
					response?.configurations && setUpdatedItems(response.configurations);
					const isConfigValid = response?.configurations?.find((config) => {
						if (!config.validationResult?.isValid) {
							setErrorBannerType(["NewOrderConfigurationErrorBanner"]);
						}
						return config.validationResult?.isValid;
					});
					let hasError = false;
					const foundLineItemError =
						response?.configurations?.reduce((accum: LineItemErrors, config) => {
							config?.lineItems?.forEach((lineItem) => {
								const validationResults =
									config?.lineItemValidationResults?.[lineItem?.lineItemNumber ?? ""];
								if (validationResults !== undefined && !validationResults?.isValid) {
									hasError = true;
								}
								if (lineItem[lineItemIdSymbol] && validationResults?.errorMessage) {
									accum[lineItem[lineItemIdSymbol]] = validationResults?.errorMessage;
								}
							});
							return accum;
						}, {}) ?? {};

					if (foundLineItemError && hasError) {
						setLineItemErrors(foundLineItemError);
						setErrorBannerType(["NewOrderLineItemErrorBanner"]);
					}

					if (isConfigValid && !hasError) {
						updateOrderDetails(updatedItems, dispatch, draftOrder, isAddOn, params.orderId);
						if (isAddOn) {
							navigateTo(`/add-ons/view-price-estimates/${params.orderId}`);
						} else {
							navigateTo("/new-order/view-price-estimates");
						}
					}
				});
		} catch (error: any) {
			if (error.status === 400) {
				if ("errors" in error && error.errors[0].code === 4001) {
					setErrorBannerType(["NewOrder4001ErrorBanner"]);
				} else {
					setErrorBannerType(["NewOrder400ErrorBanner"]);
				}
				setPostError(error);
			}

			if (error.status === 502) {
				setPostError(error);
				setErrorBannerType(["NewOrder502ErrorBanner"]);
			}
		}
	};

	const [searchValue, setSearchValue] = useState<string | null>("");
	const [searchOptions, setSearchOptions] = useState<string[]>([]);
	const [removeItem, setRemoveItem] = useState(false);

	const defineLineItemToBeRemoved = (
		item: models["PendingLineItemViewModel"],
		itemIndex: number,
		configIndex: number
	) => {
		selectedRemovedItem.current = item;
	};

	const noLineItems = useMemo(() => {
		return updatedItems?.length === 0;
	}, [updatedItems]);

	useEffect(() => {
		updatedItems?.forEach((config) => {
			setSearchOptions((prev) =>
				prev.concat(config?.lineItems?.map((item) => `${item.sku} - ${item.description}`) ?? [])
			);
		});

		if (noLineItems) {
			setErrorBannerType(undefined);
		}
	}, [updatedItems, noLineItems]);

	const waitTimeBanner = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (showWaitMessage) {
			waitTimeBanner?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [showWaitMessage]);

	const handleLineItemSearch = (_event: SyntheticEvent, values: string | null) => {
		const foundSearch = searchOptions.find((option) =>
			option.toUpperCase().includes((values as string).toUpperCase())
		);
		setSearchValue(foundSearch ? values : "");
	};

	const handleOpenRemoveModal = (
		item: models["PendingLineItemViewModel"],
		itemIndex: number,
		configIndex: number
	) => {
		setRemoveItem(true);
		defineLineItemToBeRemoved(item, itemIndex, configIndex);
		selectedRemovedItem.current = item;
	};

	const handleConfirm = () => {
		getUpdatedItems();
		const lineItemId = selectedRemovedItem?.current?.[lineItemIdSymbol];
		setLineItemErrors((prev) => {
			const errors = { ...prev };
			if (lineItemId) errors[lineItemId] = undefined;
			return errors;
		});
		selectedRemovedItem.current = undefined;
		setRemoveItem(false);
	};

	const generatePrice = useCheckForUpdatedItemsBillToAccountErrors(
		handleGeneratePriceEstimate,
		{ configurations: updatedItems },
		handleUpdateAccount,
		handleUpdateBillTo
	);
	const dialogState = useSelector((state: RootState) => state.newOrder.leaveNewOrderFlow);

	if (dialogState?.state === "pause" && noLineItems) {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(newOrderActions.resetNewOrderFlow());
		navigateTo("/new-order");
	}

	useEffect(() => {
		if (draftOrder && updatedItems) {
			dispatch(newOrderActions.overwriteDraftOrder({ ...draftOrder, configurations: updatedItems }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedItems, dispatch]);

	useEffect(() => {
		if (!isLoading && tempParsedCSV) {
			const csvConfigs: models["PendingOrderConfigurationViewModel"][] =
				(parsedCSV || [])
					.flatMap((file) => file?.configurations || [])
					?.map(
						(config) =>
							draftOrder?.configurations?.find(
								(draftConfig) => draftConfig.configurationId === config?.configurationId
							) ?? config
					) ?? [];

			const draftConfigurations =
				draftOrder?.configurations?.filter(
					(config) => !csvConfigs?.find((csvConfig) => csvConfig?.configurationId === config.configurationId)
				) ?? [];
			setUpdatedItems([...draftConfigurations, ...csvConfigs]);
			dispatch(newOrderActions.clearTempParsedCSV());
			dispatch(newOrderActions.overwriteDraftOrder({ ...draftOrder, configurations: csvConfigs }));
			getUpdatedItems();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedCSV, dispatch]);

	const getUpdatedItems = () => {
		setUpdatedItems((prevState) => {
			if (prevState) {
				return shuffleLineItems(prevState, selectedRemovedItem);
			}
		});
	};

	const [removeGroup, setRemoveGroup] = useState(false);

	const handleRemoveGroup = (config: models["PendingOrderConfigurationViewModel"]) => {
		setRemoveGroup(true);
		selectedRemovedConfiguration.current = config;
	};

	const handleDialogClose = () => {
		selectedRemovedConfiguration.current = undefined;
		selectedRemovedItem.current = undefined;
		setRemoveItem(false);
		setRemoveGroup(false);
	};

	const getUpdatedConfigs = () => {
		const newUpdatedConfigs = updatedItems?.filter(
			(configuration) => configuration !== selectedRemovedConfiguration?.current
		);
		if (newUpdatedConfigs) setUpdatedItems(shuffleLineItems(newUpdatedConfigs, selectedRemovedItem));
	};

	// Remove group
	const handleGlobalConfirm = () => {
		getUpdatedConfigs();
		setRemoveGroup(false);
		setLineItemErrors((prev) => {
			const errors = { ...prev };
			selectedRemovedConfiguration.current?.lineItems?.forEach((lineItem) => {
				const lineItemId = lineItem?.[lineItemIdSymbol];
				if (lineItemId) errors[lineItemId] = undefined;
			});

			return errors;
		});
		selectedRemovedConfiguration.current = undefined;
	};

	const handleLineItem = (configurationId: string, newLineItem: models["PendingLineItemViewModel"]) => {
		setUpdatedItems((prev) => {
			if (prev) {
				const workingCopy = prev?.slice(0);
				const workingConfigIndex = workingCopy?.findIndex(
					(config) => config.configurationId === configurationId
				);
				const workingConfig = workingCopy?.[workingConfigIndex];
				const config = { ...workingConfig, lineItems: [...(workingConfig?.lineItems ?? []), newLineItem] };
				workingCopy[workingConfigIndex] = config;
				return workingCopy;
			}
			return prev;
		});

		getUpdatedItems();
	};

	const handleMoveLineItem = (
		item: models["PendingLineItemViewModel"],
		deleteItemIndex: number,
		deleteConfigId: string | null | undefined,
		destinationConfigId: string | null | undefined
	) => {
		setUpdatedItems((prev) => {
			if (!prev) return prev;

			// Create a deep copy of the previous state
			const workingCopy = [...prev];
			const deleteConfigIndex = workingCopy?.findIndex((config) => config.configurationId === deleteConfigId);
			const destinationConfigIndex = workingCopy?.findIndex(
				(config) => config.configurationId === destinationConfigId
			);

			if (deleteConfigIndex === destinationConfigIndex) {
				// If the source and destination are the same, update the item in place
				const sourceConfig = workingCopy[deleteConfigIndex];
				if (sourceConfig?.lineItems) {
					const updatedLineItems = [...sourceConfig.lineItems];
					updatedLineItems[deleteItemIndex] = { ...item };

					workingCopy[deleteConfigIndex] = {
						...sourceConfig,
						lineItems: updatedLineItems
					};
				}
			} else {
				// Remove the item from the source configuration
				const sourceConfig = workingCopy[deleteConfigIndex];
				if (sourceConfig?.lineItems) {
					const updatedSourceLineItems = [...sourceConfig.lineItems];
					updatedSourceLineItems.splice(deleteItemIndex, 1);

					workingCopy[deleteConfigIndex] = {
						...sourceConfig,
						lineItems: updatedSourceLineItems
					};
				}

				// Add the item to the destination configuration
				const destinationConfig = workingCopy[destinationConfigIndex];
				if (destinationConfig?.lineItems) {
					const updatedDestinationLineItems = [...destinationConfig.lineItems, item];

					workingCopy[destinationConfigIndex] = {
						...destinationConfig,
						lineItems: updatedDestinationLineItems
					};
				}
			}

			return workingCopy;
		});

		getUpdatedItems();
	};

	const errorBannerElement = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (errorBannerType) {
			errorBannerElement?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [errorBannerType]);

	if (isLoading) {
		return (
			<NewOrderSkeletonContainer data-testid="build-order-loading-skeleton">
				<BuildNewOrderLineItemsContainer>
					<LabelSkeletonContainer>
						{showWaitMessage ? (
							<Alert
								ref={waitTimeBanner}
								data-testid="loading-banner"
								severity="info"
							>
								<AlertTitle>{SlowLoadBannerTitleText}</AlertTitle>
								{SlowLoadMessageText}
							</Alert>
						) : (
							<>
								<Skeleton height={32} />
								<Skeleton height={32} />
								<Skeleton height={32} />
							</>
						)}
					</LabelSkeletonContainer>
					<LineItemLoadingSkeleton
						showHeaderSkeleton={false}
						showInfoSkeleton={false}
					/>
				</BuildNewOrderLineItemsContainer>
			</NewOrderSkeletonContainer>
		);
	}

	return (
		<BuildNewOrderLineItemsContainer>
			<NewOrdersSubheader
				isLoading={isLoading}
				title={NewOrderBuildOrderLabel}
				dataTestId="new-order-build-label-header"
				hasSearch
				handleSearch={(event, values) => handleLineItemSearch(event, (values as string) ?? "")}
				searchOptions={searchOptions}
				searchValue={searchValue ?? ""}
				autoCompleteId="build-new-order-line-items-search"
				draftOrder={draftOrder}
				hasAddGlobalAttributesButton
				isAddOn={isAddOn}
			/>
			{errorBannerType && (
				<ErrorBanner
					errorMessaging={handlePriceValidationErrorMessaging(errorBannerType, postError)}
					isLoading={isLoading}
					ref={errorBannerElement}
				/>
			)}
			{noLineItems || !updatedItems ? (
				<DefaultMessage
					dataTestId="default-message-empty-order"
					asset="/assets/NoOrdersSadBoxImage.svg"
					altMessage="Default message no line items"
					title={EmptyFlyoutHeaderText}
					subtitle={NewOrderSubtitle}
					bullets={[NewOrderEmptyBullet1, NewOrderEmptyBullet2]}
					removeHeight
				/>
			) : undefined}
			{(removeGroup || removeItem) && (
				<LeaveFlowDialog
					dialogState={removeGroup || removeItem}
					firstButtonText={NewOrderCancelButton.toUpperCase()}
					handleOpenDraftModal={(removeGroup && handleGlobalConfirm) || handleConfirm}
					handleDialogClose={handleDialogClose}
					handleDialogExitOrder={handleDialogClose}
					secondButtonText={(removeGroup && NewOrderConfirmRemoveGroup) || NewOrderRemoveItem}
					subtitle={(removeGroup && NewOrderRemoveGroupSubtitle) || NewOrderDialogSubtitle}
					title={(removeGroup && NewOrderRemoveGroupTitle) || NewOrderDialogTitle}
				/>
			)}
			<BuildNewOrderLineItemsFlexbox>
				{groupedDisplayConfigs?.map((group, groupIndex) => {
					return (
						<FileContent
							key={group.id}
							group={group}
							isLoading={isLoading}
							isAddOn={isAddOn}
							handleUpdateAccount={handleUpdateAccount}
							handleUpdateBillTo={handleUpdateBillTo}
							handleMoveLineItem={handleMoveLineItem}
							groupIndex={groupIndex}
							handleConfigurationUpdate={handleConfigurationUpdate}
							handleRemoveGroup={handleRemoveGroup}
							handleShowEstimateButton={handleShowEstimateButton}
							searchValue={searchValue}
							lineItemErrors={lineItemErrors}
							postError={Boolean(postError)}
							handleOpenRemoveModal={handleOpenRemoveModal}
							handleLineItem={handleLineItem}
							groupedDisplayConfigs={groupedDisplayConfigs}
						/>
					);
				})}
			</BuildNewOrderLineItemsFlexbox>
			{!noLineItems && noLineItems !== undefined && showEstimateButton && (
				<NewOrderFlowButtons isLoading={isLoading}>
					<Button
						data-testid="new-order-build-continue-button"
						variant="contained"
						onClick={generatePrice}
						data-id={`${isAddOn ? "add-ons" : "new-order"}-generate-price-button`}
					>
						{NewOrderPriceEstimate}
					</Button>
				</NewOrderFlowButtons>
			)}
		</BuildNewOrderLineItemsContainer>
	);
};

export default BuildNewOrderLineItems;
