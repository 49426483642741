import { useCallback, useEffect, useState } from "react";
import { AppNavigation, CWGBranding, UserInfo } from "components";
import CompressedNavigation from "components/AppNavigation/CompressedNavigation";
import { UserNavigationItemViewModel } from "data/api/v1/model/user-navigation-item-view-model";
import { BrandingWrapper, CloseMenuIcon, NavWrapper, SidebarContainer, SidebarMenuIcon } from "./sidebar.styles";
import { useLocation, useNavigate } from "react-router";
import { getNavigationLink } from "components/AppNavigation/__helpers__/navigationHelper";
import { CabinetworksGroupApplication } from "data/api/v1/model/cabinetworks-group-application";
import { ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { LinkNavigation, newOrderActions } from "features/reducers/newOrder/newOrder";
import useSidebar from "hooks/useSidebar";
import { models } from "types/api/viewModels.ts";

const NewOrderNavLink: UserNavigationItemViewModel = {
	id: "",
	title: "New Order",
	link: "new-order",
	appLink: CabinetworksGroupApplication.Tandem,
	hasNestedNavigation: false,
	navigationItems: []
};

const Sidebar = ({ userDetails }: any) => {
	const navigateTo = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const ordersTab = "nav-orders";
	const [currentPath, setCurrentPath] = useState("");
	const cartItems: ReplacementCartItem[] = useSelector((state: RootState) => state.cart.replacements);
	const deferredNavigationState: LinkNavigation | undefined = useSelector(
		(state: RootState) => state.newOrder.leaveNewOrderFlow
	);
	const [leavePageModalOpen, setLeavePageModalOpen] = useState(false);
	const [subMenuOpen, setSubMenuOpen] = useState(
		userDetails?.user?.navigation?.navigationItems?.find(
			(item: UserNavigationItemViewModel) => item.id === ordersTab
		)
	);
	const [selectedIndex, setSelectedIndex] = useState(1);
	const [selectedMainNavItem, setSelectedMainNavItem] = useState<models["UserNavigationItemViewModel"] | null>(
		userDetails?.user?.navigation?.navigationItems?.find(
			(item: UserNavigationItemViewModel) => item.id === ordersTab
		)
	);

	const { isSidebarOpen, openNav, closeNav } = useSidebar();

	const handleMVPButtonClick = useCallback(() => {
		if (
			((location.pathname.includes("new-order/") && location.pathname !== "/new-order/confirmation") ||
				(location.pathname.includes("add-ons/") && !location.pathname.includes("/add-ons/confirmation/"))) &&
			deferredNavigationState?.state !== "pause"
		) {
			dispatch(newOrderActions.leaveNewOrderFlow({ state: "pause", navItem: NewOrderNavLink, index: 0 }));
			closeNav();
			return;
		} else {
			closeNav();
			dispatch(newOrderActions.clearNewOrder());
			navigateTo("new-order");
		}
	}, [closeNav, deferredNavigationState?.state, dispatch, location.pathname, navigateTo]);

	const handleLinkNavigation = useCallback(
		(navItem: models["UserNavigationItemViewModel"], index: number) => {
			if (navItem.id === "nav-orders-place-order") {
				handleMVPButtonClick();
			}

			// allow navigation
			if (!navItem.hasNestedNavigation) {
				const url = getNavigationLink(navItem.hasNestedNavigation, navItem.appLink, navItem.link);

				if (leavePageModalOpen) {
					return;
				}
				setCurrentPath(url);
				closeNav();

				if (
					cartItems &&
					cartItems.length > 0 &&
					window.location.href.includes("/replacements") &&
					!window.location.href.includes("/confirmation")
				) {
					setLeavePageModalOpen(true);
				} else if (navItem.appLink === CabinetworksGroupApplication.Salesforce) {
					window.location.href = url;
				} else {
					navigateTo(url);
				}
			} else {
				setSubMenuOpen(true);
				setSelectedIndex(index);
				setSelectedMainNavItem(navItem);
			}

			dispatch(newOrderActions.resetNewOrderFlow());
		},
		[dispatch, handleMVPButtonClick, leavePageModalOpen, closeNav, cartItems, navigateTo]
	);

	const handleClickNavigationOption = (navItem: models["UserNavigationItemViewModel"], index: number) => {
		// Check if the user is in the new-order
		if (
			!navItem.hasNestedNavigation &&
			(location.pathname.includes("new-order") || location.pathname.includes("add-ons")) &&
			["build-order", "view-price-estimates", "shipping-details", "review-order"].some((path) =>
				location.pathname.includes(path)
			)
		) {
			dispatch(newOrderActions.leaveNewOrderFlow({ navItem, index, state: "pause" }));
			closeNav();
			return;
		} else {
			handleLinkNavigation(navItem, index);
		}
	};

	const handleCloseSubMenu = () => {
		setSubMenuOpen(false);
		setSelectedIndex(1);
	};

	useEffect(() => {
		const { state, navItem, index } = deferredNavigationState || {};

		if (state === "proceed" && navItem && index !== undefined) {
			handleLinkNavigation(navItem, index);
		}
	}, [deferredNavigationState, dispatch, handleLinkNavigation, navigateTo]);

	useEffect(() => {
		setCurrentPath(window.location.pathname);
	}, []);

	return (
		<SidebarContainer data-testid="sidebar">
			<NavWrapper
				isMainNavOpen={isSidebarOpen}
				isSubNavOpen={subMenuOpen}
			>
				<div>
					<CompressedNavigation
						handleClickNavigationOption={handleClickNavigationOption}
						handleCloseSubMenu={handleCloseSubMenu}
						mainNavigationItems={userDetails?.user?.navigation.navigationItems ?? []}
						subNavigation={selectedMainNavItem}
						selectedIndex={selectedIndex}
					/>
				</div>
				<div>
					<BrandingWrapper>
						<CWGBranding />
						<div>
							{isSidebarOpen ? (
								<CloseMenuIcon
									data-testid="mobile-nav-close-icon"
									onClick={closeNav}
								/>
							) : (
								<SidebarMenuIcon
									data-testid="mobile-nav-menu-icon"
									onClick={openNav}
								/>
							)}
						</div>
					</BrandingWrapper>
					<div>
						<UserInfo
							data-testid="user-info"
							user={userDetails?.user}
						/>
					</div>
					<div data-testid="app-navigation">
						<AppNavigation
							currentPath={currentPath}
							handleClickNavigationOption={handleClickNavigationOption}
							handleCloseSubMenu={handleCloseSubMenu}
							leavePageModalOpen={leavePageModalOpen}
							mainNavigationItems={userDetails?.user?.navigation.navigationItems ?? []}
							selectedIndex={selectedIndex}
							setLeavePageModalOpen={setLeavePageModalOpen}
							subMenuOpen={subMenuOpen}
							subNavigation={selectedMainNavItem}
						/>
					</div>
				</div>
			</NavWrapper>
		</SidebarContainer>
	);
};

export default Sidebar;
