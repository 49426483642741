import { Tab, Table, TableBody, TableRow, Tabs } from "@mui/material";
import {
	SaveDraftExteriorTableHeader,
	SaveDraftTableCell,
	SaveDraftTableContainer,
	SaveDraftTableDisclaimer,
	SaveDraftTableWrapper
} from "./SaveDraftTable.styles";
import {
	NewOrderCancelButton,
	NewOrderOpen,
	NewOrderRemoveButton,
	RemoveDraftConfirm,
	RemoveDraftModalSubtitle,
	RemoveDraftModalTitle,
	RemoveTemplateConfirm,
	RemoveTemplateModalSubtitle,
	RemoveTemplateModalTitle,
	SaveDraftDraftTab,
	SaveDraftModalDetails,
	SaveDraftTableSearchText,
	SaveDraftTemplateTab,
	SaveTemplateModalDetails,
	SaveTemplateTableSearchText
} from "../../constants";
import { Fragment, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	useDeleteDraftOrderMutation,
	useDeleteTemplateOrderMutation,
	useLazyGetDraftOrdersQuery,
	useLazyGetTemplatesQuery
} from "features/api/orderApi";
import { LinkButton } from "components/Common/Link";
import SaveDraftNoDrafts from "../SaveDraftNoDrafts";
import SaveDraft400Error from "../SaveDraft400Error";
import SaveDraftTableSkeleton from "./SaveDraftTableSkeleton";
import { format } from "date-fns";
import SortableTableHead, {
	Order
} from "components/NewOrders/SaveDraft/SaveDraftTable/components/SortableTableHead.tsx";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import SaveDraftTableFilters from "./components/SaveDraftTableFilters.tsx";
import useSession from "hooks/useSession.ts";
import { CacheKeys, InitialDraftFilterOptions } from "pages/OrderPages/OrderListPage/orderListConstants.ts";
import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage.tsx";
import { getFilteredDrafts, removeConfigWithDupeProductLine } from "./saveDraftUtils.ts";
import { UnavailableDataPlaceholderText } from "constants/text.ts";
import { useNavigate } from "react-router";
import { models } from "types/api/viewModels.ts";
import LeaveFlowDialog from "../../../Common/LeaveFlowDialog/LeaveFlowDialog.tsx";
import { CellIds, displayDraftDesigner, getComparator, headCells } from "utils/order.ts";
import SaveDraftNoDraftsFilter from "../SaveDraftNoDraftsFilter.tsx";
import { convertToTitleCase } from "utils/string.ts";
import { OrderType } from "data/api/v1/index.ts";

function a11yProps(index: number) {
	return {
		id: `new-order-save-draft-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}

const SaveDraftTable = () => {
	const [draftFilterOptions, setDraftFilterOptions] = useSession<FilterOpts>(
		CacheKeys.DraftFilters,
		InitialDraftFilterOptions
	);
	const [templateFilterOptions, setTemplateFilterOptions] = useSession<FilterOpts>(
		CacheKeys.TemplateFilters,
		InitialDraftFilterOptions
	);
	const [order, setOrder] = useState<Order>("desc");
	const [orderBy, setOrderBy] = useState<CellIds>("updatedAt");
	const [value, setValue] = useState<number>(0);
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const navigate = useNavigate();
	const [removeDraft, setRemoveDraft] = useState(false);
	const draftToRemove = useRef("");
	const [trigger, { data: drafts, error, isFetching: isDraftFetching }] = useLazyGetDraftOrdersQuery();
	const [templateTrigger, { data: templates, error: templateError, isFetching: isTemplateFetching }] =
		useLazyGetTemplatesQuery();
	const [deleteTemplate, { isLoading: deleteTemplateLoading }] = useDeleteTemplateOrderMutation();
	const [deleteDraft, { isLoading: deleteDraftLoading }] = useDeleteDraftOrderMutation();

	const sortDrafts = useCallback(
		(draftsOrTemplates: models["DraftOrderViewModel"][] | undefined) =>
			draftsOrTemplates?.slice().sort(getComparator(order, orderBy)),
		[order, orderBy]
	);
	const [sortedDrafts, setSortedDrafts] = useState(sortDrafts(drafts));
	const [isTemplateView, setIsTemplateView] = useState(false);

	// Below useEffect handles switching back and forth between Templates and Drafts tabs
	useEffect(() => {
		const triggerFetch = isTemplateView ? templateTrigger : trigger;
		triggerFetch({});
		setSortedDrafts(sortDrafts(isTemplateView ? templates : drafts));
	}, [trigger, templateTrigger, drafts, templates, isTemplateView, sortDrafts]);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
		setIsTemplateView(newValue === 1);
	};

	const handleRequestSort = (_event: MouseEvent<unknown>, property: CellIds) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleOpenDraft = (draft: models["DraftOrderViewModel"]) => {
		const isAddOn = draft.orderType === OrderType.ADDON;
		if (draft.draftOrderId) {
			if (isAddOn) {
				navigate(`/add-ons/build-order/${draft.originalOrderId}/${draft.draftOrderId}`);
			} else {
				navigate(`/new-order/build-order/${draft.draftOrderId}`);
			}
		}
	};

	const handleRemoveDraftModal = (draftId: string) => {
		if (draftId) {
			setRemoveDraft(true);
			draftToRemove.current = draftId;
		}
	};

	const closeRemoveDraftModal = () => {
		setRemoveDraft(false);
	};

	const handleRemoveDraft = () => {
		if (isTemplateView) {
			deleteTemplate(draftToRemove.current).then(() => {
				templateTrigger({});
			});
		} else {
			deleteDraft(draftToRemove.current).then(() => {
				trigger({});
			});
		}
		setRemoveDraft(false);
	};

	const getDraftNames = (draftsOrTemplates: models["DraftOrderViewModel"][] | undefined): string[] | undefined =>
		draftsOrTemplates
			?.filter((draft) => Boolean(draft))
			.map((draft: models["DraftOrderViewModel"]) => draft?.draftName ?? "");

	const searchOptions = useMemo(
		() => getDraftNames(isTemplateView ? templates : drafts),
		[drafts, templates, isTemplateView]
	);

	const handleDraftSearch = (_event: SyntheticEvent, values: string | null) => {
		setSearchValue(values ?? null);
	};

	const filteredDrafts = useMemo(
		() => getFilteredDrafts(isTemplateView ? templateFilterOptions : draftFilterOptions, sortedDrafts ?? []),
		[draftFilterOptions, isTemplateView, sortedDrafts, templateFilterOptions]
	);

	const filteredDraftRows = filteredDrafts?.filter((draft) =>
		draft.draftName?.toUpperCase()?.includes(searchValue?.toUpperCase() ?? "")
	);

	const filteredHeadCells = useMemo(
		() => headCells.filter((cell) => !(isTemplateView && cell.id === "orderType")),
		[isTemplateView]
	);

	const getDraftTableRow = (draft: models["DraftOrderViewModel"]) => {
		return (
			<Fragment>
				<SaveDraftTableCell
					data-testid="new-order-save-draft-table-date-content"
					component="th"
					scope="row"
				>
					{format(new Date(String(draft.updatedAt)), "MM/dd/yy")}
				</SaveDraftTableCell>
				<SaveDraftTableCell
					data-testid="new-order-save-draft-table-draft-name-content"
					isDynamicWidth
				>
					{draft.draftName}
				</SaveDraftTableCell>
				<SaveDraftTableCell
					data-testid="new-order-save-draft-table-account-content"
					isDynamicWidth
				>
					{draft.configurations?.find((config) => config.accountNumber) ? (
						removeConfigWithDupeProductLine(draft)
							.configurations?.filter(
								(configuration, index, self) =>
									self.findIndex((item) => item.accountNumber === configuration.accountNumber) ===
									index
							)
							.map((configuration) => (
								<div key={configuration.accountId}>
									{`${configuration.accountNumber} - ${configuration.accountName}`}
								</div>
							))
					) : (
						<div>{UnavailableDataPlaceholderText}</div>
					)}
				</SaveDraftTableCell>

				<SaveDraftTableCell
					data-testid="new-order-save-draft-table-designer-name-content"
					isDynamicWidth
				>
					{displayDraftDesigner(draft?.designerName, draft?.designerNumber)}
				</SaveDraftTableCell>
				<SaveDraftTableCell data-testid="new-order-save-draft-table-brand-content">
					{draft.configurations?.find((config) => config.globals?.productLine?.description) ? (
						removeConfigWithDupeProductLine(draft).configurations?.map((configuration) => (
							<div key={configuration.globals?.productLine?.id}>
								{configuration.globals?.productLine?.description}
							</div>
						))
					) : (
						<div>{UnavailableDataPlaceholderText}</div>
					)}
				</SaveDraftTableCell>
				{!isTemplateView && (
					<SaveDraftTableCell data-testid="new-order-save-draft-table-order-type">
						{convertToTitleCase(draft?.orderType)}
					</SaveDraftTableCell>
				)}
				<SaveDraftTableCell>
					<LinkButton
						onClick={() => handleOpenDraft(draft)}
						data-id={`${draft.orderType === OrderType.ADDON ? "add-ons" : "new-order"}-save-${isTemplateView ? "template" : "draft"}-table-edit-link`}
						data-testid="new-order-save-draft-table-edit-link"
					>
						{NewOrderOpen}
					</LinkButton>

					<LinkButton
						variant="remove"
						data-testid="new-order-save-draft-table-remove-link"
						onClick={() => handleRemoveDraftModal(draft.draftOrderId ?? "")}
					>
						{NewOrderRemoveButton}
					</LinkButton>
				</SaveDraftTableCell>
			</Fragment>
		);
	};

	const displayLoadingSkeleton = isDraftFetching || deleteDraftLoading || deleteTemplateLoading || isTemplateFetching;
	const isAvailable = !isDraftFetching && !deleteDraftLoading && !deleteTemplateLoading;

	return (
		<>
			{removeDraft && (
				<LeaveFlowDialog
					firstButtonText={NewOrderCancelButton.toUpperCase()}
					dialogState={removeDraft}
					secondButtonText={isTemplateView ? RemoveTemplateConfirm : RemoveDraftConfirm}
					subtitle={isTemplateView ? RemoveTemplateModalSubtitle : RemoveDraftModalSubtitle}
					title={isTemplateView ? RemoveTemplateModalTitle : RemoveDraftModalTitle}
					handleDialogClose={closeRemoveDraftModal}
					handleDialogExitOrder={closeRemoveDraftModal}
					handleOpenDraftModal={() => handleRemoveDraft()}
				/>
			)}

			{displayLoadingSkeleton ? (
				<SaveDraftTableSkeleton />
			) : (
				<SaveDraftTableContainer>
					<SaveDraftExteriorTableHeader>
						<div>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Save draft table tabs"
							>
								<Tab
									data-testid="new-order-save-draft-draft-tab"
									label={SaveDraftDraftTab}
									{...a11yProps(0)}
								/>
								<Tab
									data-testid="new-order-save-draft-template-tab"
									label={SaveDraftTemplateTab}
								/>
							</Tabs>
						</div>
						{drafts && !isTemplateView && (
							<SaveDraftTableFilters
								activeView="draft"
								filterOptions={draftFilterOptions}
								setFilterOptions={setDraftFilterOptions}
								drafts={drafts}
							/>
						)}

						{templates && isTemplateView && (
							<SaveDraftTableFilters
								activeView="template"
								filterOptions={templateFilterOptions}
								setFilterOptions={setTemplateFilterOptions}
								drafts={templates}
							/>
						)}

						<SuggestedSearch
							ariaLabel="search-drafts"
							dataTestId="new-order-save-draft-table-search"
							dropDownOpenOnChar={3}
							placeholder={isTemplateView ? SaveTemplateTableSearchText : SaveDraftTableSearchText}
							handleSearch={(event, values) => handleDraftSearch(event, values ?? "")}
							id="new-order-draft-table-search-drafts"
							searchOptions={searchOptions ?? []}
							searchValue={searchValue ?? ""}
							size="small"
						/>
					</SaveDraftExteriorTableHeader>

					<SaveDraftTableDisclaimer>
						{isTemplateView
							? SaveTemplateModalDetails.SaveTableDisclaimerText
							: SaveDraftModalDetails.SaveTableDisclaimerText}
					</SaveDraftTableDisclaimer>

					{!isTemplateView && error && "status" in error && error.status === 400 && <SaveDraft400Error />}
					{isTemplateView && templateError && "status" in templateError && templateError.status === 400 && (
						<SaveDraft400Error />
					)}

					{sortedDrafts && sortedDrafts?.length > 0 && isAvailable ? (
						filteredDraftRows && filteredDraftRows.length > 0 ? (
							<SaveDraftTableWrapper>
								<Table
									data-testid="new-order-save-draft-table"
									aria-label="Save draft table"
								>
									<SortableTableHead
										orderBy={orderBy}
										order={order}
										onRequestSort={handleRequestSort}
										headCells={filteredHeadCells}
									/>
									<TableBody>
										{filteredDraftRows?.map((draft) => (
											<TableRow key={draft.draftOrderId}>{getDraftTableRow(draft)}</TableRow>
										))}
									</TableBody>
								</Table>
							</SaveDraftTableWrapper>
						) : (
							<SaveDraftNoDraftsFilter
								SaveDraftTemplateModalDetails={
									isTemplateView ? SaveTemplateModalDetails : SaveDraftModalDetails
								}
							/>
						)
					) : (
						<SaveDraftNoDrafts />
					)}
				</SaveDraftTableContainer>
			)}
		</>
	);
};

export default SaveDraftTable;
