import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import ConfigurationsContext from "./ConfigurationsContext";
import { useGetConfigurationsQuery } from "features/api/configurationsApi";

const ConfigurationProvider = ({ children }: PropsWithChildren) => {
	const { data: configData, error: configError } = useGetConfigurationsQuery({}, { pollingInterval: 300000 });
	const [isBannerVisible, setIsBannerVisible] = useState(false);

	useEffect(() => {
		if (configData?.maintenanceBanner) {
			setIsBannerVisible(true);
		} else if (!configData?.maintenanceBanner && !configError) {
			setIsBannerVisible(false);
		}
	}, [configError, configData]);

	const contextValue = useMemo(
		() => ({
			configurationsData: configData,
			isBannerVisible
		}),
		[configData, isBannerVisible]
	);
	return <ConfigurationsContext.Provider value={contextValue}>{children}</ConfigurationsContext.Provider>;
};

export default ConfigurationProvider;
