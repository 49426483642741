import { DialogTitle } from "../Dialog";
import {
	CSVFileUploads,
	HeaderTitleStyles,
	NewOrderModalDisclaimer,
	UnsetSecondaryCopy,
	UploadCSVDragDropButton,
	UploadCSVDragDropContent,
	UploadCSVDragDropUploadFile,
	UploadModalCSVFileInfoContainerButton
} from "./NewOrders.styles";
import {
	NewOrderContinueButton,
	NewOrderModalDisclaimerText,
	NewOrderModalDragFile,
	NewOrderModalImportCSV,
	NewOrderModalSupportedFiles,
	NewOrderSelectedFiles
} from "./constants";
import { Content } from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles";
import UploadFile from "@mui/icons-material/UploadFile";
import { Button, Typography } from "@mui/material";
import { BrowseFiles, DownloadLatestText, HereText, SupportedFilesHeader } from "constants/text";
import Dragdrop from "../Dragdrop/Dragdrop";
import { useEffect, useState } from "react";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { useUploadCSVMutation } from "features/api/newOrderApi.ts";
import { useDispatch, useSelector } from "react-redux";
import { NewOrderModalTracking } from "data/api/v1";
import { useLazyGetAccountsQuery } from "features/api/accountApi.ts";
import Link from "@mui/material/Link";
import { salesforceBaseURL } from "constants/endpoints.ts";
import { useLocation, useParams } from "react-router";
import { RootState } from "stores/application.store.tsx";
import CSVFileUploadProgress from "./CSVFileUploadProgress/CSVFileUploadProgress";
import { trackGA4Event } from "utils/googleAnalytics";
import { models } from "types/api/viewModels";

interface CsvUploadProps {
	handleClose: () => void;
}

interface CSVFile extends File {
	isSuccess?: boolean;
	errors?: any;
	isAttemptedValidation: boolean;
	uploadNotFound?: boolean;
	uploadFileNull?: boolean;
	uploadNoConfigurations?: boolean;
	uploadUnsupportedFile?: boolean;
	fetchError?: boolean;
	uploadUnauthorized?: boolean;
	uploadGenericError?: boolean;
}

const CSVUpload = ({ handleClose }: CsvUploadProps) => {
	const [csvFile, setCSVFile] = useState<CSVFile[]>([]);
	const [uploadNewOrderCSV, { reset }] = useUploadCSVMutation();
	const [getAccountByProductLine] = useLazyGetAccountsQuery();
	const dispatch = useDispatch();
	const location = useLocation();
	const params = useParams();
	const draftOrder = useSelector((state: RootState) => state.newOrder.draftDetails?.draftOrder);
	const isAddOn = location.pathname.includes("add-ons/");

	const updateFiles = (selectedFiles: Array<CSVFile>) => {
		reset();
		const updatedFiles = selectedFiles.map((file) =>
			Object.assign(new File([file], file.name, { type: file.type, lastModified: file.lastModified }), {
				isAttemptedValidation: false
			})
		);
		setCSVFile((prev) => [...prev, ...updatedFiles]);
	};

	const onClose = () => {
		handleClose();
	};

	const [isUpdating, setIsUpdating] = useState<boolean>(false);

	const submitCSV = async () => {
		for (let i = 0; i < csvFile.length; i++) {
			if (csvFile[i]?.isAttemptedValidation) continue;
			setCSVFile((prev) =>
				prev.map((file, index) =>
					index === i
						? Object.assign(
								new File([file], file.name, { type: file.type, lastModified: file.lastModified }),
								{
									isAttemptedValidation: true
								}
							)
						: file
				)
			);
			const formData = new FormData();
			const file = csvFile[i]!;
			const { name, lastModified, type } = file;
			const fileWithoutErrorText = new File([file], name, { type, lastModified });
			formData.append("file", fileWithoutErrorText);
			setIsUpdating(true);
			try {
				const importedCSVData: models["ImportedOrderViewModel"] | undefined =
					await uploadNewOrderCSV(formData).unwrap();
				const fetchedProductLineAccounts: string[] = [];

				if (
					(location.pathname === "/new-order/build-order" ||
						location.pathname === `/new-order/build-order/${params.draftId}` ||
						location.pathname === `/add-ons/build-order/${params.orderId}`) &&
					importedCSVData
				) {
					dispatch(
						newOrderActions.addTempParsedCSV({
							importedCSV: importedCSVData,
							existingConfigs: draftOrder?.configurations ?? []
						})
					);
				}

				if (
					location.pathname !== "/new-order/build-order" &&
					location.pathname !== `/new-order/build-order/${params.draftId}` &&
					location.pathname !== `/add-ons/build-order/${params.orderId}` &&
					importedCSVData
				) {
					dispatch(newOrderActions.addParsedCSV(importedCSVData));
				}

				for (let configuration of importedCSVData?.configurations ?? []) {
					const productLineCode = configuration?.globals?.productLine?.code;

					if (productLineCode && !fetchedProductLineAccounts.includes(productLineCode)) {
						const accountInfo = await getAccountByProductLine(productLineCode).unwrap();
						dispatch(newOrderActions.addProductLineAccountInfo([productLineCode, accountInfo]));
						fetchedProductLineAccounts.push(productLineCode);
					}
				}

				setCSVFile((prev) =>
					prev.map((file, index) =>
						index === i
							? Object.assign(
									new File([file], file.name, { type: file.type, lastModified: file.lastModified }),
									{ isSuccess: true, isAttemptedValidation: true }
								)
							: file
					)
				);
			} catch (error: any) {
				trackGA4Event({
					event: `${isAddOn ? "Add Ons" : "New Order"} - Landing Page - Import fail`,
					eventCategory: isAddOn ? "add ons" : "new order",
					eventAction: "error",
					eventLabel: `${isAddOn ? "add ons" : "new order"} import csv failure`
				});
				const errors = error.errors || [];
				const containsErrorCode = (code: number | string) => errors.some((err: any) => err.code === code);
				const containsMessage = (message: string) => errors.some((err: any) => err.message?.includes(message));
				const errorCodes = {
					uploadBadFormat: containsErrorCode(400),
					uploadNotFound: containsErrorCode(404),
					uploadUnauthorized: containsErrorCode(403),
					fetchError: containsErrorCode("FETCH_ERROR") || containsErrorCode("PARSING_ERROR"),
					uploadFileNull: containsErrorCode(400) && containsMessage("File must have a value"),
					uploadNoConfigurations:
						containsErrorCode(400) && containsMessage("No configurations found in file"),
					uploadUnsupportedFile:
						csvFile[i]?.type !== "text/csv" ||
						(containsErrorCode(400) && containsMessage("Unsupported file"))
				};
				const uploadGenericError =
					!errorCodes.uploadNotFound &&
					!errorCodes.uploadFileNull &&
					!errorCodes.uploadNoConfigurations &&
					!errorCodes.uploadUnsupportedFile &&
					!errorCodes.uploadUnauthorized &&
					!errorCodes.fetchError;
				setCSVFile((prev) =>
					prev.map((file, index) =>
						index === i
							? Object.assign(
									new File([file], file.name, { type: file.type, lastModified: file.lastModified }),
									{
										...errorCodes,
										uploadGenericError,
										errors,
										isAttemptedValidation: true
									}
								)
							: file
					)
				);
				console.error(error);
			}
		}
		setIsUpdating(false);
	};

	useEffect(() => {
		setCSVFile([]);
	}, []);

	const removeFile = (index: number) => {
		setCSVFile((prev) => prev.filter((_, i) => i !== index));
	};

	const continueAction = () => {
		if (csvFile.some((file) => !file.isAttemptedValidation)) {
			submitCSV();
		} else {
			dispatch(newOrderActions.setModalState(NewOrderModalTracking.SELECT_BILLTO_ACCOUNT));
		}
	};

	return (
		<div>
			<HeaderTitleStyles>
				<DialogTitle
					data-testid="new-order-csv-upload-title"
					title={NewOrderModalImportCSV}
					handleClose={onClose}
				/>
			</HeaderTitleStyles>

			<Content>
				<NewOrderModalDisclaimer data-testid="new-order-csv-upload-disclaimer">
					{!isUpdating ? <p>{NewOrderModalDisclaimerText}</p> : ""}
				</NewOrderModalDisclaimer>
				<Dragdrop
					accept="csv"
					onFileUpload={updateFiles}
				>
					<UploadCSVDragDropUploadFile>
						<UploadFile />
					</UploadCSVDragDropUploadFile>

					<UploadCSVDragDropContent>
						<p data-testid="new-order-csv-upload-drag-text">{NewOrderModalDragFile}</p>

						<UploadCSVDragDropButton>
							<Button
								variant="outlined"
								data-testid="new-order-csv-upload-browse-button"
							>
								{BrowseFiles}
							</Button>
						</UploadCSVDragDropButton>

						<p data-testid="new-order-csv-upload-supported-file-text">
							{SupportedFilesHeader}{" "}
							<span style={{ fontWeight: 500 }}>{NewOrderModalSupportedFiles}</span>
						</p>
					</UploadCSVDragDropContent>
				</Dragdrop>
				{csvFile.length > 0 && (
					<div>
						<Typography
							variant="subtitle1"
							sx={{ textTransform: "uppercase", letterSpacing: "1px" }}
							data-testid="new-order-csv-upload-selected-file-text"
						>
							{NewOrderSelectedFiles}
						</Typography>
						<CSVFileUploads>
							{csvFile.map((file, index) => (
								<CSVFileUploadProgress
									file={file}
									key={file.name}
									hasError={!!file.errors}
									errors={file.errors}
									isSuccess={file.isSuccess}
									isUpdating={isUpdating}
									isAttemptedValidation={file.isAttemptedValidation}
									uploadNotFound={file.uploadNotFound}
									uploadFileNull={file.uploadFileNull}
									uploadNoConfigurations={file.uploadNoConfigurations}
									uploadUnsupportedFile={file.uploadUnsupportedFile}
									fetchError={file.fetchError}
									uploadUnauthorized={file.uploadUnauthorized}
									uploadGenericError={file.uploadGenericError}
									removeFile={() => removeFile(index)}
									isAddOn={isAddOn}
								/>
							))}
							{csvFile.find(
								(file) => file.uploadNotFound || file.uploadNoConfigurations || file.uploadGenericError
							) && (
								<UnsetSecondaryCopy style={{ marginTop: "8px" }}>
									{DownloadLatestText}{" "}
									<Link
										href={`${salesforceBaseURL}/s/design-software`}
										data-testid="newOrder-download-latest-catalogs"
									>
										{HereText}
									</Link>
								</UnsetSecondaryCopy>
							)}
						</CSVFileUploads>

						{(!csvFile.find((file) => Boolean(file.errors)) ||
							csvFile.some((file) => !file.isAttemptedValidation)) &&
							!isUpdating && (
								<UploadModalCSVFileInfoContainerButton>
									<Button
										data-id={`${isAddOn ? "add-ons" : "new-order"}-import-csv-button`}
										data-testid="new-order-csv-upload-selected-file-continue-button"
										variant="contained"
										onClick={continueAction}
									>
										{NewOrderContinueButton}
									</Button>
								</UploadModalCSVFileInfoContainerButton>
							)}
					</div>
				)}
			</Content>
		</div>
	);
};

export default CSVUpload;
